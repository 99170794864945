<template>
  <div class="login" v-if="!isProcessing">
    <div class="login__area">
      <h1 class="login__area__title">LDW 管理者専用ページ</h1>
      <v-btn class="login__area__btn" @click="signin()" depressed>Googleでログインする</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  mounted () {
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  },
  methods: {
    /**
     * ログイン
     */
    signin () {
      this.$store.commit('setSubmitting', true)

      this.$store.dispatch('auth/signin', 'google')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';
@import '@/assets/sass/color.scss';

.login {
  position: relative;
  width: 100vw;
  max-width: $max_width;
  min-height: calc(100vh - #{$header_height});
  padding: $padding_width $padding_width;
  margin: $header_height auto 0 auto;
  &__area {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    transform: translateY(-50%) translateX(-50%);
    &__title {
      margin: 0;
      font-size: 1.6rem;
      font-weight: normal;
      text-align: center;
    }
    &__btn {
      display: block;
      margin: 200px auto 0 auto;
      color: $white_color;
      text-transform: initial;
      border-radius: 10px;
      &.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
        background-color: $orange_color;
      }
      &.v-btn:not(.v-btn--round).v-size--default {
        min-width: auto;
        height: auto;
        padding: 10px 15px;
      }
    }
  }
}
</style>
